body {
  background-color: #000;
}

.page {
  max-width: 1280px;
  margin: 0 auto;
  overflow-x: hidden;
  background-color: #000;
  font-family: "Inter", sans-serif;
}
