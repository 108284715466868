.register {
  display: flex;
  justify-content: center;
}

.register__form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 0 10px;
  max-width: 358px;
  width: 100%;
}

@media screen and (max-width: 375px) {
  .register__form {
    max-width: 280px;
  }
}

.register__title {
  color: white;
  font-weight: 900;
  font-size: 24px;
  margin: 0;
}

@media screen and (max-width: 375px) {
  .register__title {
    font-size: 20px;
  }
}

.register__input {
  margin: 30px 0 0;
  width: 100%;
  height: 27px;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px solid rgb(255, 255, 255);
  background-color: transparent;
  color: white;
}

.register__button {
  margin: 216px 0 0;
  width: 100%;
  padding: 15px 0;
  font-size: 18px;
  font-weight: 400;
  cursor: pointer;
}

.register__login-link {
  color: white;
  font-weight: 400;
  font-size: 14px;
  margin: 15px 0 0;
  cursor: pointer;
}

.login {
  display: flex;
  justify-content: center;
}

.login__form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 0 10px;
  max-width: 358px;
  width: 100%;
}

@media screen and (max-width: 375px) {
  .login__form {
    max-width: 280px;
  }
}

.login__title {
  color: white;
  font-weight: 900;
  font-size: 24px;
  margin: 0;
}

@media screen and (max-width: 375px) {
  .login__title {
    font-size: 20px;
  }
}

.login__input {
  margin: 30px 0 0;
  width: 100%;
  height: 27px;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px solid rgb(255, 255, 255);
  background-color: transparent;
  color: white;
}

.login__button {
  margin: 216px 0 0;
  width: 100%;
  padding: 15px 0;
  font-size: 18px;
  font-weight: 400;
  cursor: pointer;
}

.login__login-link {
  color: white;
  font-weight: 400;
  font-size: 14px;
  margin: 15px 0 0;
  cursor: pointer;
}

.login__error-message {
  color: red;
}

@media screen and (max-width: 375px) {
  .login__error-message {
    font-size: 12px;
  }
}
