.popup {
  display: none;
}

.popup_opened {
  display: flex;
  justify-content: center;
}

.popup__container {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 20%;
}

@media screen and (max-width: 768px) {
  .popup__container {
    top: 12%;
  }
}

.popup__close-icon {
  width: 21px;
  height: 21px;
  position: absolute;
  top: -21px;
  right: -21px;
  cursor: pointer;
}

@media screen and (max-width: 620px) {
  .popup__close-icon {
    top: -32px;
    right: -2px;
  }
}

.popup__close-icon:hover {
  opacity: 0.6;
}

.popup__form-title {
  font-size: 24px;
  font-weight: 900;
  margin: 30px 0 0;
}

@media screen and (max-width: 768px) {
  .popup__form-title {
    font-size: 20px;
    margin: 20px 0 0;
  }
}

@media screen and (max-width: 620px) {
  .popup__form-title {
    font-size: 18px;
  }
}

.popup__form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 380px;
  border-radius: 10px;
  padding-bottom: 30px;
}

@media screen and (max-width: 768px) {
  .popup__form {
    padding-bottom: 20px;
    width: 325px;
  }
}

@media screen and (max-width: 620px) {
  .popup__form {
    width: 282px;
  }
}

.popup__input {
  width: 320px;
  height: 28px;
  margin: 30px 0 5px;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px solid grey;
}

@media screen and (max-width: 768px) {
  .popup__input {
    width: 280px;
    margin: 20px 0 5px;
    font-size: 14px;
  }
}

@media screen and (max-width: 620px) {
  .popup__input {
    max-width: 238px;
    font-size: 14px;
  }
}

.popup__input_type_error {
  border-bottom: 1px solid red;
}

.popup__error {
  color: red;
  display: flex;
  align-self: flex-start;
  margin: 0 0 0 28px;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  max-width: 324px;
}

@media screen and (max-width: 620px) {
  .popup__error {
    max-width: 238px;
    margin: 0 0 0 20px;
  }
}

.popup__required {
  color: rgb(90, 90, 90);
  font-size: 12px;
  font-weight: 400;
  margin: 20px 0;
}

@media screen and (max-width: 768px) {
  .popup__required {
    margin: 15px 0;
    font-size: 10px;
  }
}

.popup__button {
  width: 324px;
  height: 50px;
  color: white;
  font-size: 18px;
  font-weight: 400;
  background-color: #3952cb;
  cursor: pointer;
  border-style: none;
  border-radius: 5px;
}

@media screen and (max-width: 768px) {
  .popup__button {
    width: 280px;
  }
}

@media screen and (max-width: 620px) {
  .popup__button {
    width: 238px;
    font-size: 14px;
    height: 45px;
  }
}

.popup__button:hover {
  background-color: #1c38c2;
}

.popup__button_disabled {
  width: 324px;
  height: 50px;
  color: white;
  font-size: 18px;
  font-weight: 400;
  background-color: rgb(197, 196, 196);
  border: none;
  pointer-events: none;
}

@media screen and (max-width: 620px) {
  .popup__button_disabled {
    width: 238px;
    font-size: 14px;
    height: 45px;
  }
}

.popup__overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: -1;
}

.popup-image {
  display: none;
}

.popup-image_opened {
  display: flex;
  justify-content: center;
}

.popup-image__container {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 12%;
}

.popup-image__close-icon {
  width: 25px;
  height: 25px;
  position: absolute;
  top: -30px;
  right: -30px;
  cursor: pointer;
  z-index: 1;
}

@media screen and (max-width: 620px) {
  .popup-image__close-icon {
    width: 15px;
    height: 15px;
    top: -27px;
    right: 15px;
  }
}

.popup-image__close-icon:hover {
  opacity: 0.6;
}

.popup-image__card {
  position: relative;
  max-width: 75vw;
  max-height: 75vh;
  z-index: 1;
}

@media screen and (max-width: 620px) {
  .popup-image__card {
    max-width: 100vw;
  }
}

.popup-image__title {
  color: white;
  font-size: 16px;
  font-weight: 400;
  margin: 5px 0 0 20px;
}

@media screen and (max-width: 425px) {
  .popup-image__title {
    font-size: 12px;
  }
}

.popup-image__overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: -5;
}

.popup-remove {
  display: none;
}

.popup-remove_opened {
  display: flex;
  justify-content: center;
}

.popup-remove__container {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 27%;
}

.popup-remove__close-icon {
  width: 21px;
  height: 21px;
  position: absolute;
  top: -21px;
  right: -21px;
  cursor: pointer;
}

@media screen and (max-width: 620px) {
  .popup-remove__close-icon {
    top: -27px;
    right: -2px;
  }
}

.popup-remove__close-icon:hover {
  opacity: 0.6;
}

.popup-remove__confirm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 380px;
  border-radius: 10px;
  padding-bottom: 30px;
}

@media screen and (max-width: 620px) {
  .popup-remove__confirm {
    width: 282px;
    padding-bottom: 22px;
  }
}

.popup-remove__title {
  font-size: 24px;
  font-weight: 900;
  margin: 30px 0 15px;
}

@media screen and (max-width: 620px) {
  .popup-remove__title {
    margin: 22px 0 15px;
    font-size: 18px;
  }
}

.popup-remove__button {
  width: 324px;
  height: 50px;
  color: white;
  font-size: 18px;
  font-weight: 400;
  background-color: #3952cb;
  cursor: pointer;
  border-style: none;
  border-radius: 5px;
}

@media screen and (max-width: 620px) {
  .popup-remove__button {
    width: 238px;
    font-size: 14px;
    height: 45px;
  }
}

.popup-remove__button:hover {
  background-color: #1c38c2;
}

.popup-remove__overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: -1;
}

.infotooltip {
  display: flex;
  justify-content: center;
}

.infotooltip__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 430px;
  height: 330px;
  border-radius: 10px;
  top: 20%;
  position: fixed;
  z-index: 2;
}

@media screen and (max-width: 495px) {
  .infotooltip__container {
    width: 280px;
  }
}

.infotooltip__close-icon {
  width: 21px;
  height: 21px;
  position: absolute;
  top: -21px;
  right: -21px;
  cursor: pointer;
}

@media screen and (max-width: 495px) {
  .infotooltip__close-icon {
    top: -31px;
    right: 1px;
  }
}

.infotooltip__info-icon {
  width: 120px;
  height: 120px;
}

@media screen and (max-width: 495px) {
  .infotooltip__info-icon {
    width: 100px;
    height: 100px;
  }
}

.infotooltip__message {
  color: black;
  font-size: 24px;
  font-weight: 900;
  line-height: 30px;
  max-width: 358px;
  margin: 32px 0 0;
  text-align: center;
}

@media screen and (max-width: 495px) {
  .infotooltip__message {
    font-size: 20px;
  }
}

.infotooltip__overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1;
}
