@font-face {
  src: url(../vendor/fonts/Inter-Regular.woff2) format("woff2"),
    url(../vendor/fonts/Inter-Regular.otf) format("opentype");
  font-family: "Inter";
  font-weight: 400;
}

@font-face {
  src: url(../vendor/fonts/Inter-Medium.woff2) format("woff2"),
    url(../vendor/fonts/Inter-Medium.otf) format("opentype");
  font-family: "Inter";
  font-weight: 500;
}

@font-face {
  src: url(../vendor/fonts/Inter-Black.woff2) format("woff2"),
    url(../vendor/fonts/Inter-Black.otf) format("opentype");
  font-family: "Inter";
  font-weight: 900;
}
